<template>
  <div>
    <!-- top -->
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft" title="审核卡片" left-arrow>
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <!-- <template #right>
          <div class="rightSelect" @click="open = true">
            <van-icon name="filter-o" class="icon" />
            筛选
          </div>
        </template> -->
      </van-nav-bar>
    </van-sticky>
    <Drawer
      class="drawerWrap"
      v-if="open"
      @close="open = false"
      align="right"
      :closeable="true"
    >
      <div class="content">
        <p class="title">搜寻筛选</p>
        <div class="drawerContentWrap">
          <label for="">会员账号</label>
          <div class="pickerWrap">
            <input
              v-model="searchData.username"
              :type="'text'"
              autocomplete="off"
            />
          </div>
          <div class="footBtnWrap">
            <van-button plain class="reset" @click="clearSelect"
              >重设</van-button
            >
            <van-button
              class="use"
              @click="
                bankList = [];
                saveData = false;
                currentPage = 1;
                getBank();
              "
              >搜寻</van-button
            >
          </div>
        </div>
      </div>
    </Drawer>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <div class="tableWrap" v-for="list in bankList" :key="list.id">
        <p class="tableContent">
          <span class="tableTitle"> 会员账号</span>
          <span class="tablesContent">
            {{ list.username }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 会员名称</span>
          <span class="tablesContent">
            {{ list.name }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 银行卡号 </span>
          <span class="tablesContent">
            {{ list.bank_no }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 开户银行 </span>
          <span class="tablesContent">
            {{ list.bank_name }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 姓名 </span>
          <span class="tablesContent">
            {{ list.bank_owner }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 类型 </span>
          <span class="tablesContent">
            {{ list.type_ch }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 状态 </span>
          <span
            class="tablesContent"
            :style="{ color: list.enable == 2 ? 'red' : '' }"
          >
            {{ list.enable_ch }}
          </span>
        </p>
        <div
          class="editBtn"
          :style="{ borderTop: `1px solid ` + $returnColor().color2 }"
        >
          <p
            @click="getImg(list)"
            :style="{ borderRight: `1px solid ` + $returnColor().color2 }"
          >
            查看图片
          </p>
          <p
            v-if="list.enable == 2"
            @click="checkBank(list)"
            :style="{ color: $returnColor().color2 }"
          >
            设为启用
          </p>
          <p v-else @click="unCheckBank(list)">设为待审核</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ImagePreview } from "vant";
import Drawer from "vue-simple-drawer";
// import { Toast } from "vant";
// import Multiselect from "vue-multiselect";
// import * as imageConversion from "image-conversion";
export default {
  components: { Drawer },
  data() {
    return {
      open: false,
      next_page: true,
      wait: false,
      current_page: 1,
      bankSelect: false,
      show: true,
      newPassword: "",
      password: "",
      paginate: {},
      bankList: [],
      addBankModal: false,
      editBankModal: false,
      addBankData: { type: 0 },
      currentPage: 1,
      currentData: {},
      banks: [],
      files: [],
      delBankModal: false,
      searchData: {},
    };
  },
  watch: {
    // currentPage() {
    //   this.getBank();
    // },
  },
  methods: {
    clearSelect() {
      this.searchData = {};
      this.getBank();
    },
    getImg(list) {
      this.$http.get(`/mobile/bankCards/${list.card_id}/imgs`).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.length) {
            this.imgPrev(res.data.data);
          } else {
            this.$dialog.alert({ message: "无上传图片" });
          }
        }
      });
    },
    imgPrev(url) {
      ImagePreview({
        images: url,
        closeable: true,
      });
    },
    checkBank(list) {
      this.$dialog
        .confirm({
          title: "设为启用",
          message: `${list.bank_no}`,
        })
        .then(() => {
          this.$http
            .post(`/mobile/bankCards/${list.card_id}`, { enable: 1 })
            .then((res) => {
              if (res.data.code == 0) {
                list.enable = 1;
                this.$toast.success("成功");
                this.bankList.sort((a, b) => {
                  return b.enable - a.enable;
                });
              }
            });
        })
        .catch(() => {});
    },
    unCheckBank(list) {
      this.$dialog
        .confirm({
          title: "设为待审核",
          message: `${list.bank_no}`,
        })
        .then(() => {
          this.$http
            .post(`/mobile/bankCards/${list.card_id}`, { enable: 2 })
            .then((res) => {
              if (res.data.code == 0) {
                list.enable = 2;
                this.$toast.success("成功");
                this.bankList.sort((a, b) => {
                  return b.enable - a.enable;
                });
              }
            });
        })
        .catch(() => {});
    },
    getBank() {
      let params = {};
      if (this.saveData) {
        params = this.saveData;
      } else {
        params = this.searchData;
      }
      params.page = this.currentPage;
      this.$http
        .get(`/mobile/bankCards`, {
          params,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.wait = false;
            this.next_page = res.data.next_page;
            let listData = JSON.parse(JSON.stringify(this.bankList));
            if (this.currentPage == 1) {
              listData = [];
            }
            res.data.data.forEach((data) => {
              let have = false;
              this.bankList.forEach((e) => {
                if (e.card_id == data.card_id) {
                  have = true;
                }
              });
              if (!have) {
                listData.push(data);
              }
            });
            this.bankList = listData;
          }
        });
    },
    onClickLeft() {
      this.$router.push(this.$cookie.get("prevPage") || "/");
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          if (this.wait) return;
          this.wait = true;
          this.currentPage += 1;
          this.getBank();
        }
      }
    },
  },
  created() {
    this.getBank();
    this.$http.get(`/mobile/banks`).then((res) => {
      this.banks = res.data.data;
    });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
  computed: {
    ...mapState([
      "showOverlay",
      // ...
    ]),
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tableWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 355px;
  padding: 10px 0px;
  padding-bottom: 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  .tableContent {
    padding: 0 20px;
    width: 100%;
    display: flex;
    font-size: 14px;
    height: 25px;
    align-items: center;
    .tableTitle {
      font-weight: 400;
      white-space: nowrap;
      width: 60px;
      text-align: left;
      margin-right: 10px;
    }
    .tablesContent {
      color: #999999;
      word-break: break-all;
    }
    // &:nth-child(odd) {
    //   padding-right: 10px;
    // }
    // &:nth-child(even) {
    //   padding-left: 10px;
    // }
  }
}

.addAcc {
  display: flex;
  align-items: center;
  // color: #009aaa;
  font-size: 14px;
  .plus {
    color: #009aaa !important;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
    //
  }
}
.editBtn {
  height: 45px;
  text-align: center;
  // color: #009aaa;
  // border-top: 1px solid #009aaa;
  width: 100%;
  display: flex;
  margin-top: 10px;
  p {
    width: 50%;
    line-height: 45px;
    &:first-child {
      // border-right: 1px solid #009aaa;
    }
  }
}
.drawerWrap {
  color: #333333 !important;
  .content {
    .title {
      font-size: 16px;
      font-weight: 500;
      background-color: rgb(242, 242, 242);
      line-height: 50px;
      padding-left: 10px;
    }
  }
  .drawerContentWrap {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    label {
      color: #333333;
      margin-top: 5px;
      line-height: 30px;
    }
    .drawerTime {
      width: 115px;
      // height: 24px;
    }
    .pickerWrap {
      display: flex;
      align-items: center;
      input {
        width: 100%;
      }
    }
  }
  .footBtnWrap {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 80px;
      height: 24px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .reset {
      border: 0.5px solid #009aaa;
      color: #009aaa;
    }
    .use {
      background-color: #009aaa;
      color: white;
    }
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
